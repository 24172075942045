
import { defineComponent } from 'vue';
import ServiceItemComponent from "@/components/ServiceItemComponent.vue";
import { ServiceItem } from "@/models/ServiceItem";

export default defineComponent({
  name: 'Services',
  components: {
    ServiceItemComponent
  },
  
  data(){    
    return {
      services: [] as ServiceItem[]
    }
  },
  
  mounted(){
      this.services = [
      {id: 1, name:"geboorte.", filterName: 'GEBOORTE', color: "#F4AF24",
        description: "Volledig op maat en volgens jullie wensen. Groot geluk, klein verpakt.",} as ServiceItem,
      {id: 2, name:"huwelijk.", filterName: 'HUWELIJK', color: "#FACCC1",
        description: "Volop dromen van jullie grote dag met drukwerk om 'JA' tegen te zeggen.",} as ServiceItem,
      {id: 3, name:"branding.", filterName: 'BRANDING', color: "#E37B5A",
        description: "Jouw onderneming met de nodige power op de kaart zetten? Let's talk!",} as ServiceItem
    ] as ServiceItem[]
  }
});
