export class ServiceItem {
    id: number;
    name: string;
    filterName: string;
    description: string;
    color: string;
    
    constructor(id: number, name: string, filterName: string, description: string, color: string) {
        this.id = id;
        this.name = name;
        this.filterName = filterName;
        this.description = description;
        this.color = color;
    }
}