
import { defineComponent } from 'vue';
import { ServiceItem } from "@/models/ServiceItem";

export default defineComponent({
  name: 'ServiceItemComponent',
  props: {
    item: ServiceItem,
  },
});
